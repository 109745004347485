import $ from 'jquery';
import Utils from '../../../utils';
export default class AjaxFormWithHtmlResponse {
    xhrRequest = null;
    $ajaxFormWrapper;
    constructor(wrapperSelector = 'body', callback) {
        this.$ajaxFormWrapper = $(wrapperSelector);
        this.initAjaxActions(callback);
    }
    initAjaxActions(callback) {
        this.$ajaxFormWrapper.find('.js__ajax-form-with-html-response').on('submit', e => {
            e.preventDefault();
            const $form = $(e.currentTarget);
            const url = $form.attr('action');
            const data = $form.serialize();
            const method = $form.attr('method');
            const $errorMessage = $($form.data('error-message-selector'));
            const contentSpinnerSelector = $form.data('content-spinner-selector');
            const contentLoadSelector = $form.data('content-load-selector');
            const confirmationRequired = $form.data('confirmation-required');
            const redirectUrl = $form.data('success-redirect-url');
            const notRedirectTemplateWithErrors = $form.data('not-redirect-template-with-errors');
            const $confirmationModal = $($form.data('confirmation-modal-selector'));
            if ($form.valid()) {
                if (!url || !method) {
                    return;
                }
                if (confirmationRequired) {
                    const $confirmationButton = $confirmationModal.find('.js__confirm');
                    $confirmationModal.modal();
                    $confirmationModal.on('hidden.bs.modal', () => {
                        $confirmationButton.off('click');
                    });
                    $confirmationButton.on('click', () => {
                        this.ajaxRequest(url, method, data, $errorMessage, contentSpinnerSelector, contentLoadSelector, redirectUrl, notRedirectTemplateWithErrors, callback);
                    });
                }
                else {
                    this.ajaxRequest(url, method, data, $errorMessage, contentSpinnerSelector, contentLoadSelector, redirectUrl, notRedirectTemplateWithErrors, callback);
                }
            }
        });
    }
    ajaxRequest(url, method, data, $errorMessage, contentSpinnerSelector, contentLoadSelector, redirectUrl, notRedirectTemplateWithErrors, callback) {
        if (this.xhrRequest) {
            this.xhrRequest.abort();
            this.xhrRequest = null;
        }
        Utils.contentLoader($(contentSpinnerSelector), true);
        this.xhrRequest = $.ajax({
            url,
            method,
            data,
            cache: false,
        })
            .done(data => {
            const templateIncludeError = data.includes('invalid-feedback');
            if ((redirectUrl && !notRedirectTemplateWithErrors) ||
                (redirectUrl && notRedirectTemplateWithErrors && !templateIncludeError)) {
                window.location.replace(redirectUrl);
                return;
            }
            const $content = $(contentLoadSelector);
            $content.html(data);
            $content.find('.js__ajax-form-with-html-response').data('success-redirect-url', redirectUrl);
            this.initAjaxActions(callback);
            Utils.contentLoader($(contentSpinnerSelector), false);
            callback($content);
        })
            .fail(() => {
            Utils.contentLoader($(contentSpinnerSelector), false);
            if ($errorMessage.length) {
                $errorMessage.removeClass('d-none');
            }
        });
    }
}
