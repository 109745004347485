import $ from 'jquery';
export default class FadedScroll {
    static init($root = $('body')) {
        const $element = $root.find('.js__faded-scroll');
        $element.each((index, element) => {
            const $wrapper = $(element);
            const $content = $wrapper.children();
            $content.on('scroll', event => {
                this.addFadedClasses($content);
            });
            this.addFadedClasses($content);
            const $modal = $wrapper.closest('.modal');
            if ($modal.length !== 0) {
                $modal.on('shown.bs.modal', event => {
                    this.addFadedClasses($content);
                });
            }
        });
    }
    static addFadedClasses($scroller) {
        const hasScrollBar = $scroller[0].scrollHeight > $scroller.innerHeight();
        const isAtTheTop = $scroller.scrollTop() <= 0;
        const isAtTheBottom = $scroller[0].scrollHeight - $scroller.scrollTop() - $scroller.innerHeight() < 1;
        $scroller
            .parent()
            .toggleClass('faded-top', hasScrollBar && !isAtTheTop)
            .toggleClass('faded-bottom', hasScrollBar && !isAtTheBottom);
    }
}
