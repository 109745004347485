import $ from 'jquery';
const INPUT_SELECTOR = '.js__code-input';
const INPUT_FIELD_SELECTOR = '.js__code-input-field';
const INPUT_HIDDEN_FIELD_SELECTOR = '.js__code-hidden-input';
export default class CodeInput {
    static $codeHiddenInput;
    static init() {
        if (!$(INPUT_SELECTOR).length) {
            return;
        }
        this.initCodeInput();
        this.initCodeInputSMSAutofill();
    }
    static initCodeInput() {
        this.$codeHiddenInput = $(INPUT_HIDDEN_FIELD_SELECTOR);
        const $firstCodeInput = $(INPUT_FIELD_SELECTOR).first();
        $firstCodeInput.off('click').on('click', () => {
            setTimeout(() => {
                $firstCodeInput.trigger('focus');
                $firstCodeInput.get(0).setSelectionRange(0, 0);
            }, 100);
        });
        if (this.$codeHiddenInput.data('focus-on-init')) {
            $firstCodeInput.trigger('click');
        }
        $(INPUT_SELECTOR)
            .closest('form')
            .on('submit', e => {
            if ($(e.currentTarget).valid()) {
                $(INPUT_FIELD_SELECTOR).each((index, element) => {
                    $(element).prop('disabled', true);
                });
            }
        });
        $(INPUT_SELECTOR).each((i, element) => {
            $(element)
                .find(INPUT_FIELD_SELECTOR)
                .each((index, element) => {
                const $element = $(element);
                $element.on('keydown', e => {
                    if (e.keyCode === 8 && e.target.value === '') {
                        $(element).closest(INPUT_SELECTOR).find(INPUT_FIELD_SELECTOR)[Math.max(0, index - 1)].focus();
                    }
                });
                $element.on('input', e => {
                    const $hideElement = $(this.$codeHiddenInput.data('hide-element-on-change'));
                    if ($hideElement.length) {
                        $hideElement.addClass('d-none');
                    }
                    const [first, ...rest] = e.target.value;
                    const $fields = $element.closest(INPUT_SELECTOR).find(INPUT_FIELD_SELECTOR);
                    e.target.value = first ?? '';
                    const lastInputBox = index === $fields.length - 1;
                    const insertedContent = first !== undefined;
                    if (insertedContent && !lastInputBox && rest.length) {
                        $fields[index + 1].focus();
                        $fields[index + 1].value = rest.join('');
                        $fields[index + 1].dispatchEvent(new Event('input'));
                    }
                    const code = $fields
                        .map((_, elem) => elem.value)
                        .get()
                        .join('');
                    this.$codeHiddenInput.val(code);
                });
            });
        });
    }
    static initCodeInputSMSAutofill() {
        if ('OTPCredential' in window) {
            const $input = $('input[autocomplete="one-time-code"]');
            if (!$input.length) {
                return;
            }
            const ac = new AbortController();
            const $form = $input.closest('form');
            navigator.credentials
                .get({
                // @ts-ignore
                otp: { transport: ['sms'] },
                signal: ac.signal,
            })
                .then(otp => {
                // @ts-ignore
                $input.val(otp.code).trigger('input');
                if ($form.length) {
                    $form.find('button[type="submit"]').trigger('click');
                }
            })
                .catch(err => {
                console.log(err);
            });
        }
    }
}
