import $ from 'jquery';
import AjaxForm from './ajax-form';
import Utils from '../../utils';
import PasswordVisibilityToggle from './password-visibility-toggle';
import { toggleButtonLoader } from '../../utils/button-loader';
import DisableOnTargetValue from './disable-on-target-value';
import SwitchElements from './switch-elements';
import SingleProductSlider from '../../components/single-product-slider';
import SingleProductColorSelect from '../../entries/shop-product/components/single-product-color-select';
import CustomizableBundleProducts from '../../entries/shop-product/components/customizable-bundle-products';
import CheckboxGaEvents from '../../components/checkbox-ga-events';
export default class AjaxModal {
    static xhrRequest = null;
    static init() {
        $(document).on('click', '.js__ajax-modal-btn:not(.js__ignore-ajax-modal)', e => {
            e.preventDefault();
            const $button = $(e.currentTarget);
            const modalUrl = $button.data('url');
            const successUrl = $button.data('success-url');
            if ($button.data('mobile-redirect') && Utils.isMobile()) {
                window.location.assign(modalUrl);
            }
            else {
                AjaxModal.show($button, modalUrl, successUrl, ($newModal) => {
                    const successTemplate = $newModal.find('form').data('success-template');
                    const $ajaxForm = $newModal.find('.js__ajax-form');
                    new AjaxForm($ajaxForm, successUrl, AjaxModal.formSubmitSuccessHandler($newModal, successUrl, successTemplate, $ajaxForm.data('keep-modal-open-on-success'), $ajaxForm.data('new-template-container')));
                });
            }
        });
        const $loadOnInitButton = $('.js__ajax-modal-btn.js__init-on-load');
        $loadOnInitButton.trigger('click');
    }
    static show($button, modalUrl, successUrl, callback) {
        const isModalWithNavigation = $button.data('modal-with-navigation');
        toggleButtonLoader($button, true, $button.data('is-large-loader'));
        let $modal;
        if (AjaxModal.xhrRequest) {
            AjaxModal.xhrRequest.abort();
            AjaxModal.xhrRequest = null;
        }
        AjaxModal.xhrRequest = $.ajax({
            url: modalUrl,
            method: 'GET',
            cache: false,
        })
            .done(data => {
            const $body = $('body');
            $modal = $(data);
            if ($modal.length > 1) {
                window.location.replace(successUrl || modalUrl);
            }
            else {
                $body.append($modal);
                const isCloseDisabled = $button.data('is-close-disabled');
                const modalOptions = isCloseDisabled
                    ? {
                        backdrop: 'static',
                        keyboard: false,
                    }
                    : {};
                if (isCloseDisabled) {
                    $modal.find('[data-dismiss="modal"]').remove();
                }
                $modal.modal(modalOptions);
                callback($modal);
                $modal.on('hidden.bs.modal', e => {
                    $modal.next('.grecaptcha-badge').remove();
                    $(e.currentTarget).remove();
                });
                PasswordVisibilityToggle.init($modal);
                CheckboxGaEvents.init($modal);
                DisableOnTargetValue.init($modal);
                SwitchElements.init();
                CustomizableBundleProducts.init();
                setTimeout(() => {
                    SingleProductSlider.init();
                    SingleProductColorSelect.init();
                }, 300);
                $modal.find('.js__ajax-modal-btn').on('click', () => {
                    $modal.modal('hide');
                });
                const $modalBackdrop = $('.modal-backdrop');
                if ($modalBackdrop.length > 1) {
                    $modalBackdrop.first().remove();
                }
                if (isModalWithNavigation) {
                    $(document.body).addClass('modal-with-navigation');
                }
            }
        })
            .always(() => {
            toggleButtonLoader($button, false);
        });
    }
    static formSubmitSuccessHandler($modal, successUrl, successTemplate, keepModalOpen, newTemplateContainerSelector) {
        return (data, hideModalOnSuccess) => {
            if ((successUrl && !keepModalOpen) || hideModalOnSuccess) {
                $modal.modal('hide');
            }
            else if (successTemplate) {
                if (newTemplateContainerSelector) {
                    const $newTemplateContainer = $modal.find(newTemplateContainerSelector);
                    $newTemplateContainer.html(data);
                    $modal.find('.modal-body').addClass('d-none');
                    $newTemplateContainer.removeClass('d-none');
                    $modal.modal('handleUpdate');
                }
                else {
                    $modal.find('.modal-body').html(data);
                    $modal.modal('handleUpdate');
                }
            }
        };
    }
}
