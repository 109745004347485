import $ from 'jquery';
import { toggleButtonLoader } from '../../utils/button-loader';
export default class SegmentationConsentModal {
    static $modal = $('.js__segmentation-consent-modal');
    static $actionBtn = $('.js__segmentation-action-btn');
    static closedByAction = false;
    static init() {
        if (!this.$modal.length) {
            return;
        }
        this.initModal();
    }
    static initModal() {
        this.initConsentActions();
        this.$modal
            .modal({
            backdrop: 'static',
            keyboard: false,
        })
            .on('hide.bs.modal', () => {
            this.handleDismiss();
        });
    }
    static initConsentActions() {
        this.$actionBtn.on('click', e => {
            this.postConsentAction($(e.currentTarget));
        });
    }
    static postConsentAction($btn) {
        if ($btn.prop('disabled')) {
            return;
        }
        this.$actionBtn.not($btn).prop('disabled', true);
        toggleButtonLoader($btn, true);
        this.closedByAction = true;
        const ruleId = this.$actionBtn.data('rule-id');
        const consent = this.$actionBtn.data('consent');
        const segmentationConsentModalData = {
            ...(ruleId && { rule_id: ruleId }),
            consent: consent,
        };
        $.post($btn.data('action-url'), segmentationConsentModalData).then(() => {
            this.$modal.modal('hide');
        });
    }
    static handleDismiss() {
        if (!this.closedByAction) {
            $.post(this.$modal.data('dismiss-url'));
        }
    }
}
