import $ from 'jquery';
export default class BrowserBackButton {
    static $backButton = $('.js__browser-back-button');
    static init() {
        if (!this.$backButton.length) {
            return;
        }
        const isBackAllowedOnlyToSameDomain = this.$backButton.data('back-only-to-same-domain');
        const isDomainMatch = document.referrer.includes(window.location.hostname);
        if (isBackAllowedOnlyToSameDomain && !isDomainMatch) {
            this.$backButton.on('click', () => {
                window.location.assign(this.$backButton.data('fallback-url'));
            });
        }
        else {
            this.$backButton.on('click', this.initBrowserBack);
        }
    }
    static initBrowserBack(e) {
        e.preventDefault();
        window.history.back();
    }
}
