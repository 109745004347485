import Swiper from 'swiper';
import { Breakpoint } from '../../types/breakpoint';
import ContentSameSize from './content-same-size';
export default class SliderWithTwoPerSlideMobile {
    static $sliders = $('.js__slider-with-two-per-slide-mobile');
    static swipers = [];
    static options = {
        centeredSlides: false,
        slidesPerView: 2,
        spaceBetween: 24,
        watchOverflow: true,
        on: {
            init: function () {
                ContentSameSize.init();
            },
        },
    };
    static init() {
        if (!this.$sliders.length) {
            return;
        }
        this.$sliders.each((index, slider) => {
            const options = {
                ...this.options,
                ...($(slider).data('mobile-single-slide-per-view') && {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    centerInsufficientSlides: true,
                }),
                ...{
                    breakpoints: {
                        [Breakpoint.lg]: {
                            slidesPerView: $(slider).data('desktop-slides-count') || 4,
                            spaceBetween: 0,
                            centeredSlides: false,
                            centerInsufficientSlides: false,
                        },
                    },
                },
            };
            this.swipers[index] = new Swiper(slider, options);
        });
    }
}
