import $ from 'jquery';
export default class InputsLogic {
    static init() {
        if ($('.js__master-checkbox').length && $('.js__slave-checkbox').length) {
            this.initCheckboxDependantOnSingleCheckbox();
        }
    }
    static initCheckboxDependantOnSingleCheckbox() {
        const $masterCheckbox = $('.js__master-checkbox');
        $masterCheckbox.on('change', e => {
            const $slaveCheckbox = $('.js__slave-checkbox');
            if ($(e.currentTarget).prop('checked')) {
                $slaveCheckbox.prop('disabled', false);
            }
            else {
                $slaveCheckbox.prop('disabled', true);
                $slaveCheckbox.prop('checked', false);
            }
        });
        $masterCheckbox.trigger('change');
    }
}
