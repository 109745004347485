import $ from 'jquery';
import throttle from 'lodash.throttle';
import GaTracking from './ga-tracking';
export default class DataLayer {
    static DOMstr = {
        ageGateLangSelector: '.js__lang-select',
        navLangSelector: '.js__data-layer-language-switcher',
    };
    static init() {
        const $body = $('body');
        const journeyPage = $body.data('journey-page');
        let currentJourneySection = '';
        const $sectionEventElement = $('.js__section-scroll-event');
        $body.on('click', this.DOMstr.ageGateLangSelector, e => {
            GaTracking.pushDataLayer({
                event: 'languageChange',
                langSelector: 'age gate',
            });
        });
        $(this.DOMstr.navLangSelector).on('click', e => {
            GaTracking.pushDataLayer({
                event: 'languageChange',
                langSelector: 'Navigation',
            });
        });
        if (journeyPage && $sectionEventElement.length) {
            $(window).on('scroll', throttle(e => {
                const windowScrollPosition = $(e.currentTarget).scrollTop() || 0;
                $($sectionEventElement.get().reverse()).each((i, element) => {
                    const $section = $(element);
                    const isTriggerOnSectionBottom = $section.data('journey-trigger-on-section-bottom');
                    const sectionOffset = $section.offset() || { top: 0 };
                    const sectionHeight = $section.height() || 0;
                    const journeySection = $section.data('journey-section');
                    const journeySectionDatalayer = $section.data('journey-section-datalayer');
                    const journeySectionMinHeight = $section.data('journey-min-height') || 0;
                    const isTrigger = isTriggerOnSectionBottom
                        ? windowScrollPosition + ($(window).height() || 0) > sectionOffset.top + sectionHeight
                        : windowScrollPosition > sectionOffset.top;
                    if (isTrigger && sectionHeight > journeySectionMinHeight) {
                        if (currentJourneySection !== journeySection) {
                            if (journeySectionDatalayer) {
                                GaTracking.pushDataLayer(journeySectionDatalayer);
                            }
                            else {
                                GaTracking.pushDataLayer({
                                    journeyPage,
                                    journeySection,
                                    journeyNavSupport: 'Scroll',
                                    event: 'sectionView',
                                });
                            }
                            currentJourneySection = $section.data('journey-section');
                        }
                        return false;
                    }
                });
            }, 100));
            $(window).trigger('scroll');
        }
        $body.on('shown.bs.modal', '.modal', e => {
            const popupDisplayContent = $(e.currentTarget).data('modal-name');
            if (popupDisplayContent) {
                GaTracking.pushDataLayer({
                    event: 'popupDisplay',
                    popupDisplayContent,
                });
            }
        });
    }
}
