import { __decorate } from "tslib";
import $ from 'jquery';
import { throttle } from 'helpful-decorators';
import Navbar from '../../components/navbar';
export default class ScrollDirection {
    static $body = $(document.body);
    static lastOffsetTop = 0;
    static scrollUpClass = 'scroll-up';
    static scrollDownClass = 'scroll-down';
    static init() {
        $(window).on('scroll', () => {
            this.handleScroll();
        });
    }
    static handleScroll() {
        const currentOffsetTop = $(window).scrollTop();
        if (currentOffsetTop === 0) {
            this.$body.removeClass([this.scrollUpClass, this.scrollDownClass]);
            this.lastOffsetTop = currentOffsetTop;
            return;
        }
        if (currentOffsetTop > this.lastOffsetTop &&
            currentOffsetTop > Navbar.getNavbarComputedHeight() &&
            !this.$body.hasClass(this.scrollDownClass)) {
            this.$body.removeClass(this.scrollUpClass).addClass(this.scrollDownClass);
        }
        else if (currentOffsetTop < this.lastOffsetTop && this.$body.hasClass(this.scrollDownClass)) {
            this.$body.removeClass(this.scrollDownClass).addClass(this.scrollUpClass);
        }
        this.lastOffsetTop = currentOffsetTop;
    }
}
__decorate([
    throttle(300)
], ScrollDirection, "handleScroll", null);
