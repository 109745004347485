import $ from 'jquery';
const INPUT_SELECTOR = '.js__select-all-input';
export default class SelectAllInput {
    static init() {
        if (!$(INPUT_SELECTOR).length) {
            return;
        }
        this.initSelectAllInput();
    }
    static initSelectAllInput() {
        $(INPUT_SELECTOR).each((i, element) => {
            const $element = $(element);
            const $otherCheckboxes = $element.closest('form').find(`[type="checkbox"]:not(${INPUT_SELECTOR})`);
            $element.on('change', e => {
                if ($element.is(':checked')) {
                    $otherCheckboxes.prop('checked', true).trigger('change').trigger('keyup');
                }
                else {
                    $otherCheckboxes.prop('checked', false).trigger('change').trigger('keyup');
                }
            });
            $otherCheckboxes.on('change', e => {
                if (!$(e.currentTarget).is(':checked')) {
                    $element.prop('checked', false);
                }
            });
        });
    }
}
