import $ from 'jquery';
import GaTracking from './ga-tracking';
const ELEMENT_SELECTOR = '.js__switch-elements';
export default class SwitchElements {
    static init() {
        if (!$(ELEMENT_SELECTOR).length) {
            return;
        }
        this.initElementsSwitch();
    }
    static initElementsSwitch() {
        $(ELEMENT_SELECTOR).on('click', e => {
            const $button = $(e.currentTarget);
            const { showElement, hideElement, removeHidden } = $button.data();
            $(showElement).removeClass('d-none');
            $(hideElement).addClass('d-none');
            if (removeHidden) {
                $(hideElement).html('');
            }
            if ($button.data('datalayer-on-new-template')) {
                GaTracking.pushDataLayer($button.data('datalayer-on-new-template'));
            }
        });
    }
}
