import { __decorate } from "tslib";
import $ from 'jquery';
import { throttle } from 'helpful-decorators';
const CONTENT_SELECTOR = '.js__same-height-content';
export default class ContentSameSize {
    static init() {
        if (!$(CONTENT_SELECTOR).length) {
            return;
        }
        this.setContentHeight();
        $(window).on('resize', this.handleResize.bind(this));
    }
    static handleResize() {
        this.setContentHeight();
    }
    static setContentHeight() {
        const $content = $(CONTENT_SELECTOR);
        const groups = [];
        let groupHeights = [];
        $content.each((index, element) => {
            $(element).css('min-height', '');
        });
        $content.each((index, element) => {
            const group = $(element).data('group-name');
            if (!groups.includes(group)) {
                groups.push(group);
            }
        });
        groupHeights = new Array(groups.length).fill(0);
        groups.forEach((group, groupIndex) => {
            const $groupElements = $(`${CONTENT_SELECTOR}[data-group-name="${group}"]`);
            $groupElements.each((index, element) => {
                const elementHeight = $(element).height() || 0;
                if (elementHeight > groupHeights[groupIndex]) {
                    groupHeights[groupIndex] = elementHeight;
                }
            });
        });
        groups.forEach((group, groupIndex) => {
            const $element = $(`${CONTENT_SELECTOR}[data-group-name="${group}"]`);
            $element.css('min-height', groupHeights[groupIndex]);
        });
    }
}
__decorate([
    throttle(300)
], ContentSameSize, "handleResize", null);
