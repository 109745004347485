import $ from 'jquery';
import FadedScroll from './faded-scroll';
import { toggleButtonLoader } from '../../utils/button-loader';
export default class LegalInfoModal {
    static $modal = $('.js__legal-info-modal');
    static $modalContent = $('.js__legal-info-modal-content');
    static $modalScrollContent;
    static $modalForm;
    static $modalScrollableContent;
    static $modalScrollBottomButton;
    static init() {
        if (!this.$modal.length) {
            return;
        }
        $.get(this.$modal.data('status-url')).then(response => {
            if (response.status === true) {
                this.initModal();
            }
        });
    }
    static initModal() {
        this.$modal.on('shown.bs.modal', () => {
            this.$modalContent.load(this.$modalContent.data('url'), () => {
                this.initModalContent();
            });
        });
        this.$modal.modal({
            backdrop: 'static',
            keyboard: false,
        });
    }
    static initModalContent() {
        this.$modalScrollContent = this.$modalContent.find('.js__legal-info-modal-scroll-content');
        this.$modalForm = this.$modalContent.find('.js__legal-info-modal-form');
        this.$modalScrollableContent = this.$modalContent.find('.js__legal-info-modal-scroll-content');
        this.$modalScrollBottomButton = this.$modalContent.find('.js__legal-info-scroll-bottom-button');
        this.$modal.modal('handleUpdate');
        this.trackModalScrollContent();
        FadedScroll.init(this.$modalContent);
        this.$modalForm.on('submit', event => {
            this.handleModalFormSubmit(event);
        });
        if (this.$modalScrollableContent[0].scrollHeight - this.$modalScrollableContent[0].clientHeight) {
            this.$modalScrollBottomButton.removeClass('d-none');
            this.$modalScrollBottomButton.on('click', () => {
                this.$modalScrollableContent.animate({
                    scrollTop: this.$modalScrollableContent[0].scrollHeight,
                }, 1000);
            });
        }
    }
    static trackModalScrollContent() {
        let isAtTheBottom = this.isAtTheBottom(this.$modalScrollContent);
        if (isAtTheBottom) {
            this.$modalForm.find(':submit').prop('disabled', false);
        }
        this.$modalScrollContent.on('scroll', event => {
            const $element = $(event.currentTarget);
            isAtTheBottom = this.isAtTheBottom($element);
            if (isAtTheBottom) {
                this.$modalForm.find(':submit').prop('disabled', false);
            }
        });
    }
    static isAtTheBottom($element) {
        return $element[0].scrollHeight - $element.scrollTop() - $element.innerHeight() < 1;
    }
    static handleModalFormSubmit(event) {
        event.preventDefault();
        const $form = $(event.target);
        const $button = $form.find(':submit');
        if ($button.prop('disabled')) {
            return;
        }
        toggleButtonLoader($button, true);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
        }).then(() => {
            this.$modal.modal('hide');
        }, ({ responseText }) => {
            this.$modalContent.html(responseText);
            this.initModalContent();
        });
    }
}
