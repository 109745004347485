import { add, isBefore, set, sub } from 'date-fns';
import $ from 'jquery';
import Cookies from 'js-cookie';
import 'select2';
import 'bootstrap/js/dist/modal';
import { toggleButtonLoader } from '../../utils/button-loader';
import GaTracking from './ga-tracking';
export default class AgeGate {
    static $modal = $('.js__age-gate-modal');
    static $backdrop = $('.js__age-gate-backdrop');
    static $verifyStep = $('.js__age-gate-verify');
    static $deniedStep = $('.js__age-gate-denied');
    static $verifyForm = $('.js__age-gate-form');
    static $yearSelect = $('.js__age-gate-year-select');
    static $monthSelect = $('.js__age-gate-month-select');
    static $csrfToken = $('.js__age-gate-csrf-token');
    static isLoading = false;
    static init() {
        if (!this.$modal.length) {
            // This cookie is required for google tag manager to know when age gate is passed.
            Cookies.set('age_confirmed', 'true');
            return;
        }
        if (Cookies.get('age_gate_confirm_date')) {
            this.removeAgeGateModal();
        }
        Cookies.remove('age_confirmed');
        if (Cookies.get('age_time_out')) {
            this.showAgeGateDenied();
        }
        else {
            this.initVerifyForm();
            const deniedCheckInterval = setInterval(() => {
                if (Cookies.get('age_time_out')) {
                    this.showAgeGateDenied();
                    clearInterval(deniedCheckInterval);
                }
                if (Cookies.get('age_confirmed')) {
                    this.removeAgeGateModal();
                    clearInterval(deniedCheckInterval);
                }
            }, 1000);
        }
        GaTracking.pushDataLayer({
            event: 'gatewayLegalAgeDisplay',
        });
        this.$yearSelect.on('change', () => {
            if (!this.$monthSelect.val()) {
                this.$monthSelect.select2('open');
            }
        });
    }
    static initVerifyForm() {
        this.$verifyForm.on('submit', event => {
            event.preventDefault();
            const now = new Date();
            if (isBefore(set(now, { year: Number(this.$yearSelect.val()), month: Number(this.$monthSelect.val()) }), sub(now, { years: this.$modal.data('customer-legal-age') }))) {
                this.ageGatePassed();
            }
            else {
                this.ageGateDenied();
            }
        });
        this.$yearSelect.add(this.$monthSelect).on('change', () => {
            this.$verifyForm.find(':submit').prop('disabled', !this.$yearSelect.val() || !this.$monthSelect.val());
        });
    }
    static ageGatePassed() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        const $button = this.$verifyForm.find(':submit');
        toggleButtonLoader($button, true);
        $.ajax({
            url: this.$verifyForm.attr('action'),
            method: this.$verifyForm.attr('method'),
            data: { _token: this.$csrfToken.val() },
        })
            .done(() => {
            // Redirect to same url instead of reload page to not loop age-gate when redirected from email (safe-redirect, firefox fix)
            window.location.replace(window.location.href);
        })
            .fail(data => {
            toggleButtonLoader($button, false);
            if (data.status === 400) {
                window.location.replace(window.location.href);
            }
        })
            .always(() => {
            this.isLoading = false;
        });
        GaTracking.pushDataLayer({
            event: 'gatewayLegalAge',
            selectedLegalAgeBoolean: 'Yes',
        });
    }
    static ageGateDenied() {
        this.showAgeGateDenied();
        Cookies.set('age_time_out', '1', { expires: add(new Date(), { minutes: 10 }) });
        GaTracking.pushDataLayer({
            event: 'gatewayLegalAge',
            selectedLegalAgeBoolean: 'No',
        });
    }
    static removeAgeGateModal() {
        this.$modal.remove();
        this.$backdrop.remove();
        if (!$('.modal.show').length) {
            $('body').removeClass('modal-open');
        }
    }
    static showAgeGateDenied() {
        this.$verifyStep.hide();
        this.$deniedStep.show();
    }
}
