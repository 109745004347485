import $ from 'jquery';
import GaTracking from './ga-tracking';
export default class Modals {
    static init() {
        this.geoIpModal();
        $('.js__modal-open-on-load').modal('show');
        const $modal = $('.js__action-success-modal');
        $modal.each((index, item) => {
            if ($(item).data('show-modal') === 'show') {
                $(item).modal('show');
            }
        });
    }
    static geoIpModal() {
        const $geoIpModal = $('.js__geo-ip-modal');
        if ($geoIpModal.length) {
            GaTracking.pushDataLayer({
                event: 'geofenceOverlay',
            });
        }
    }
}
