import $ from 'jquery';
import { toggleButtonLoader } from '../../utils/button-loader';
export default class ProfileUpdateModal {
    static $modal = $('.js__profile-update-modal');
    static $modalContent = $('.js__profile-update-modal-content');
    static $modalForm;
    static init() {
        if (!this.$modal.length) {
            return;
        }
        this.initModal();
    }
    static initModal() {
        this.$modal.on('shown.bs.modal', () => {
            this.$modalContent.load(this.$modalContent.data('url'), () => {
                this.initModalContent();
            });
        });
        this.$modal.modal({
            backdrop: 'static',
            keyboard: false,
        });
    }
    static initModalContent() {
        this.$modalForm = this.$modalContent.find('.js__profile-update-modal-form');
        this.$modal.modal('handleUpdate');
        this.$modalForm.on('submit', event => {
            this.handleModalFormSubmit(event);
        });
    }
    static handleModalFormSubmit(event) {
        event.preventDefault();
        const $form = $(event.target);
        const $button = $form.find(':submit');
        toggleButtonLoader($button, true);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
        }).then(() => {
            this.$modal.modal('hide');
        }, ({ responseText, status }) => {
            if (403 == status || 404 == status) {
                this.$modal.modal('hide');
                return;
            }
            this.$modalContent.html(responseText);
            this.initModalContent();
        });
    }
}
