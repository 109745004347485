import * as Sentry from '@sentry/browser';
const dsn = document.body.dataset.sentryDsn;
if (dsn) {
    Sentry.init({
        dsn,
        integrations: [
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: ['shop', 'admin'],
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        ],
    });
}
