import $ from 'jquery';
export default class CookieConsentActions {
    static init() {
        $('.js__revoke-cookie-preferences').on('click', event => {
            event.preventDefault();
            if ('OneTrust' in window) {
                // @ts-ignore
                window.OneTrust.ToggleInfoDisplay();
            }
        });
    }
}
