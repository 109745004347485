import $ from 'jquery';
import GaTracking from '../core/components/ga-tracking';
export default class CheckboxGaEvents {
    static init($root = $('body')) {
        const $checkbox = $root.find('.js__checkbox-ga-events');
        if ($checkbox.length) {
            this.initCheckboxGaEvents($checkbox);
        }
    }
    static initCheckboxGaEvents($checkbox) {
        $checkbox.on('change', e => {
            const $input = $(e.currentTarget);
            if ($input.prop('checked')) {
                GaTracking.pushDataLayer($input.data('checked-ga-event'));
            }
            else {
                GaTracking.pushDataLayer($input.data('unchecked-ga-event'));
            }
        });
    }
}
