import $ from 'jquery';
import browserUpdate from 'browser-update';
/**
 * Creates new instance of Browser Update notification.
 */
export default class BrowserUpdate {
    static init() {
        const $browserUpdate = $('.js__browser-update');
        browserUpdate({
            required: {
                e: -2,
                i: 11,
                f: -3,
                o: -3,
                s: 10.1,
                c: '64.0.3282.16817',
                samsung: 7.0,
                vivaldi: 1.2,
            },
            insecure: true,
            style: 'bottom',
            text: {
                msg: $browserUpdate.data('msg'),
                msgmore: $browserUpdate.data('msgmore'),
                bupdate: $browserUpdate.data('bupdate'),
                bignore: $browserUpdate.data('bignore'),
                remind: $browserUpdate.data('remind'),
                bnever: $browserUpdate.data('bnever'),
            },
        });
    }
}
