import $ from 'jquery';
var InputFilterType;
(function (InputFilterType) {
    InputFilterType["Integer"] = "integer";
})(InputFilterType || (InputFilterType = {}));
const ELEMENT_SELECTOR = '.js__input-filter';
const INPUT_FILTER_FN_MAP = {
    [InputFilterType.Integer]: value => /^\d*$/.test(value),
};
export default class InputFilter {
    static $element;
    static init() {
        this.$element = $(ELEMENT_SELECTOR);
        this.bindInputFilter(this.$element);
    }
    static bindInputFilter($element) {
        $element.on('input keydown keyup mousedown mouseup select contextmenu drop', event => {
            const target = event.currentTarget;
            const $target = $(target);
            const data = $target.data('input-filter-data');
            const inputFilterFn = this.getInputFilterFn($target);
            if (inputFilterFn(target.value)) {
                const newData = {
                    oldValue: target.value,
                    oldSelectionStart: target.selectionStart,
                    oldSelectionEnd: target.selectionEnd,
                };
                $target.data('input-filter-data', newData);
            }
            else if (data) {
                target.value = data.oldValue;
                if (data.oldSelectionStart !== null && data.oldSelectionEnd !== null) {
                    target.setSelectionRange(data.oldSelectionStart, data.oldSelectionEnd);
                }
            }
            else {
                target.value = '';
            }
        });
    }
    static getInputFilterFn($element) {
        return INPUT_FILTER_FN_MAP[$element.data('input-filter-type')];
    }
}
