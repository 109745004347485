export const fadeSlideIn = ($target, duration = 300) => {
    if ($target.is(':visible')) {
        return;
    }
    $target.stop(true, false).fadeIn({ duration, queue: false }).css('display', 'none').slideDown(duration);
};
export const fadeSlideOut = ($target, duration = 300) => {
    if (!$target.is(':visible')) {
        $target.css('display', 'none');
        return;
    }
    $target.stop(true, false).fadeOut({ duration, queue: false }).slideUp(duration);
};
