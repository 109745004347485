import $ from 'jquery';
export default class NestedModals {
    static init() {
        $(document)
            .on('show.bs.modal', '.modal', e => {
            const $modal = $(e.target);
            const $previousModals = $('.modal[data-modal-shown="1"]');
            const $previousBackdrops = $('.modal-backdrop');
            this.changeZIndexBy($previousModals.add($previousBackdrops), -2);
            $modal.attr('data-modal-shown', 1);
        })
            .on('hidden.bs.modal', '.modal', e => {
            const $modal = $(e.target);
            $modal.removeAttr('data-modal-shown').css('z-index', '');
            const $previousModals = $('.modal[data-modal-shown="1"]');
            const $previousBackdrops = $('.modal-backdrop');
            this.changeZIndexBy($previousModals.add($previousBackdrops), 2);
            if ($('.modal:visible').length > 0) {
                $(document.body).addClass('modal-open');
            }
        });
    }
    static changeZIndexBy($elements, value) {
        $elements.each((index, element) => {
            const $element = $(element);
            const previousModalZIndex = parseInt($element.css('z-index'), 10) + value;
            $element.css('z-index', previousModalZIndex);
        });
    }
}
