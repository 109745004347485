import $ from 'jquery';
import { toggleButtonLoader } from '../../utils/button-loader';
import AjaxFormWithHtmlResponse from '../../entries/cart/components/ajax-form-with-html-response';
import FormValidate from '../../components/form-validate';
export default class AjaxButton {
    static xhrRequest = null;
    static init() {
        if ($('.js__ajax-button').length) {
            this.initAjaxRequest();
        }
    }
    static initAjaxRequest() {
        $('.js__ajax-button').on('click', e => {
            const $element = $(e.currentTarget);
            const url = $element.data('url');
            const data = $element.data('data');
            const successRedirectUrl = $element.data('success-redirect-url');
            const method = $element.data('method') || 'GET';
            const $errorMessage = $($element.data('error-message-selector'));
            const $successMessage = $($element.data('success-message-selector'));
            const confirmationRequired = $element.data('confirmation-required');
            const $confirmationModal = $($element.data('confirmation-modal-selector'));
            const contentLoadSelector = $element.data('content-load-selector');
            if (confirmationRequired) {
                const $confirmationButton = $confirmationModal.find('.js__confirm');
                $confirmationModal.modal();
                $confirmationModal.on('hidden.bs.modal', () => {
                    $confirmationButton.off('click');
                });
                $confirmationButton.on('click', () => {
                    this.ajaxRequest($element, url, method, data, $errorMessage, $successMessage, successRedirectUrl, contentLoadSelector);
                });
            }
            else {
                this.ajaxRequest($element, url, method, data, $errorMessage, $successMessage, successRedirectUrl, contentLoadSelector);
            }
        });
    }
    static ajaxRequest($element, url, method, data, $errorMessage, $successMessage, successRedirectUrl, contentLoadSelector) {
        if (this.xhrRequest) {
            this.xhrRequest.abort();
            this.xhrRequest = null;
        }
        $errorMessage.addClass('d-none');
        toggleButtonLoader($element, true);
        this.xhrRequest = $.ajax({
            url,
            method,
            data,
            cache: false,
        })
            .done(response => {
            const hide = $element.data('hide-on-success');
            if (hide) {
                $element.remove();
            }
            const closeModal = $element.data('close-modal-on-success');
            if (closeModal) {
                $element.closest('.modal').modal('hide');
            }
            if ($successMessage.length) {
                $successMessage.removeClass('d-none');
            }
            if (successRedirectUrl) {
                window.location.replace(successRedirectUrl);
            }
            if (contentLoadSelector) {
                const $content = $(contentLoadSelector);
                $content.html(response);
                new AjaxFormWithHtmlResponse(contentLoadSelector, () => {
                    FormValidate.init();
                });
            }
        })
            .fail(() => {
            if ($errorMessage.length) {
                $errorMessage.removeClass('d-none');
            }
        })
            .always(() => {
            toggleButtonLoader($element, false);
        });
    }
}
