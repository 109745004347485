let isWebpSupported;
export const checkWebpSupport = async () => {
    if (isWebpSupported === undefined) {
        // Caches the result so the check would be invoked only a single time
        isWebpSupported = new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
                const result = img.width > 0 && img.height > 0;
                resolve(result);
            };
            img.onerror = () => {
                resolve(false);
            };
            img.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
        });
    }
    return isWebpSupported;
};
