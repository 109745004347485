import $ from 'jquery';
import { toggleButtonLoader } from '../../utils/button-loader';
import Utils from '../../utils';
export default class FormLoading {
    static $form = $('.js__form-loading-on-submit');
    static init() {
        if (!this.$form.length) {
            return;
        }
        this.$form.on('submit', e => {
            const $form = $(e.currentTarget);
            if ($form.valid()) {
                toggleButtonLoader($form.find('[type="submit"]'), true);
                Utils.makeFieldsReadOnly($form);
            }
        });
    }
}
