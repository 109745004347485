import $ from 'jquery';
import { fadeSlideIn, fadeSlideOut } from '../../utils/animate';
import { clearInputValue, getInputValue } from '../../utils/input';
import { matchesCriteria } from '../../utils/criteria';
const ELEMENT_SELECTOR = '.js__disable-on-target-value';
export default class DisableOnTargetValue {
    static init($root = $('body')) {
        const $elements = $root.find(ELEMENT_SELECTOR);
        $elements.each((index, element) => {
            let $element = $(element);
            const $target = $($element.data('target'));
            $target.on('change', event => {
                this.handleValueChange($(event.target), $element);
            });
            this.handleValueChange($target, $element, 0);
        });
    }
    static handleValueChange($target, $element, duration = 300) {
        const value = getInputValue($target);
        const { criteriaValue, disabledValue, clearDisabled, hideDisabled, inverseCriteria } = $element.data();
        const disable = matchesCriteria(criteriaValue, value, inverseCriteria);
        this.disableElement($element, disable, disabledValue, clearDisabled, hideDisabled, duration);
    }
    static disableElement($element, disable, disabledValue, clearDisabled, hideDisabled, duration) {
        const $parent = $element.closest('.form-group:not(.js__toggle-element)').length
            ? $element.closest('.form-group:not(.js__toggle-element)')
            : $element.closest('.js__toggle-element');
        const isReadOnly = $element.data('readonly');
        if (isReadOnly) {
            $parent.find('input, select, textarea').prop('readonly', disable);
        }
        else {
            $parent.find('input, select, textarea').prop('disabled', disable);
        }
        if (disable && hideDisabled) {
            fadeSlideOut($parent, duration);
        }
        else {
            fadeSlideIn($parent, duration);
        }
        const $disableOnTargetValueElement = $element.find('.js__disable-on-target-value');
        if (!disable && $disableOnTargetValueElement.length) {
            $($disableOnTargetValueElement.data('target')).trigger('change');
        }
        if (disable && disabledValue) {
            $parent.find('select').val(disabledValue).trigger('change');
        }
        if (disable && clearDisabled) {
            const $dropdownInput = $parent.find('select');
            clearInputValue($dropdownInput.length ? $dropdownInput : $element);
        }
    }
}
