import { scrollToElement } from '../../utils/scroll-to-element';
export default class ScrollToByUrl {
    static init() {
        const { hash } = window.location;
        if (hash && hash !== '#') {
            let $target;
            try {
                $target = $(hash);
            }
            catch {
                return;
            }
            if ($target.length) {
                setTimeout(() => {
                    scrollToElement($target, $target.data('url-scroll-offset') || 0);
                    $target.find('.collapse:hidden').collapse('show');
                }, $target.data('url-scroll-delay') || 0);
            }
        }
    }
}
