import $ from 'jquery';
import { getInputValue } from '../../utils/input';
import { matchesCriteria } from '../../utils/criteria';
const ELEMENT_SELECTOR = '.js__set-input-value-on-target-value';
export class SetInputValueOnTargetValue {
    static init($root = $('body')) {
        const $elements = $root.find(ELEMENT_SELECTOR);
        $elements.each((index, element) => {
            let $element = $(element);
            const $target = $($element.data('target'));
            $target.on('change', event => {
                this.handleValueChange($(event.target), $element);
            });
            if ($element.data('set-value-on-load')) {
                this.handleValueChange($target, $element);
            }
        });
    }
    static handleValueChange($target, $element) {
        const value = getInputValue($target);
        const { criteriaValue, clearValue, setValue } = $element.data();
        const matched = matchesCriteria(criteriaValue, value);
        const clear = matchesCriteria(clearValue, value);
        this.updateInput($element, matched, clear, setValue);
    }
    static updateInput($element, matched, clear, setValue) {
        const $parent = $element.closest('.form-group:not(.js__toggle-element)').length
            ? $element.closest('.form-group:not(.js__toggle-element)')
            : $element.closest('.js__toggle-element');
        $parent.find('input[type="checkbox"]').prop('checked', clear ? false : matched);
        if (setValue && matched) {
            $parent.find('input, select, textarea').val(setValue);
        }
        if (clear) {
            $parent.find('input, select, textarea').val('');
        }
    }
}
