import $ from 'jquery';
import { requiredValidation, validatePassword } from '../../components/validations';
import { validationOptions } from '../../constants/options';
import { toggleButtonLoader } from '../../utils/button-loader';
export default class PasswordReset {
    static $form = $('.js__reset-password-form');
    static errors = $('.js__form-error-translations').data();
    static init() {
        if (this.$form.length) {
            this.bindEvents();
        }
    }
    static bindEvents() {
        this.$form.validate({
            ...validationOptions,
            onkeyup: element => {
                $(element).valid();
            },
            onfocusout: element => {
                $(element).valid();
            },
        });
        this.bindValidations();
        $('.js__reset-password-submit').on('click', e => {
            if (this.$form.valid()) {
                toggleButtonLoader($(e.currentTarget), true);
                this.handleSubmit();
            }
        });
    }
    static handleSubmit() {
        this.$form.submit();
    }
    static bindValidations() {
        requiredValidation($('.js__current-password-input'), this.errors.errors);
        validatePassword($('.js__reset-password-one-input'), $('.js__reset-password-two-input'), this.errors.errors, this.$form);
        requiredValidation($('.js__agreed-terms-and-privacy-policy-conditions-input'), this.errors.errors);
        requiredValidation($('.js__agreed-adult-person-input'), this.errors.errors);
    }
}
