import $ from 'jquery';
import 'jquery-validation';
import { validationOptions } from '../../constants/options';
import { toggleButtonLoader } from '../../utils/button-loader';
import SwitchElements from './switch-elements';
import { disableAllInputs, enableAllInputs } from '../../utils/input';
import LazyImages from '../../components/lazy-images';
import CodeInput from './code-input';
import Timer from '../../components/timer';
import GaTracking from './ga-tracking';
import { getEnglishErrorMessageByMessageText } from '../../utils/error';
import CheckboxGaEvents from '../../components/checkbox-ga-events';
export default class AjaxForm {
    successCallback;
    event;
    constructor($ajaxForm, successUrl = '', successCallback = () => { }) {
        this.successCallback = successCallback;
        this.event = document.createEvent('Event');
        this.event.initEvent('resetRecaptcha', true, true);
        $ajaxForm.each((i, form) => {
            const $form = $(form);
            const $submitButton = $form.find('.js__submit-modal-form');
            this.initSubmitButton($submitButton, $form.data('success-url') ? $form.data('success-url') : successUrl);
            if (!$form.data('validate-after-submit')) {
                this.initValidation($form);
                $form.data('is-validation-init', true);
            }
        });
    }
    initSubmitButton($submitButton, successUrl) {
        $submitButton.on('click', e => {
            e.preventDefault();
            const $element = $(e.currentTarget);
            const $form = $element.closest('form');
            const url = $form.attr('action');
            const $removeSpacesInputs = $form.find('.js__remove-spaces-on-submit');
            if ($removeSpacesInputs.length) {
                $.each($removeSpacesInputs, (index, element) => {
                    $(element).val(String($(element).val()).replaceAll(' ', ''));
                });
            }
            if ($form.data('validate-after-submit') && !$form.data('is-validation-init')) {
                this.initValidation($form);
                $form.data('is-validation-init', true);
            }
            const errorDatalayer = $form.data('datalayer-on-error');
            if ($form.valid()) {
                setTimeout(() => {
                    this.submitForm($form, $element, url, successUrl, $form.data('success-template'));
                });
            }
            else if (errorDatalayer) {
                const validator = $form.validate();
                GaTracking.pushDataLayer({
                    ...errorDatalayer,
                    eventLabel: `Error - ${getEnglishErrorMessageByMessageText(validator.errorList[0]?.message)}`,
                });
            }
        });
    }
    submitForm($form, $element, url, successUrl, successTemplate) {
        toggleButtonLoader($element, true);
        this.hideGeneralError($form);
        const disableFieldsOnLoad = $form.data('disable-fields-on-load');
        $form.find('.js__too-many-requests-error').addClass('d-none');
        const ajaxRequest = $.ajax({
            method: 'POST',
            url,
            data: $form.serialize(),
        })
            .done(data => {
            if (this.successCallback) {
                this.successCallback(data, $form.data('hide-modal-on-success'));
            }
            const $formToSubmitOnSuccess = $($form.data('form-to-submit-on-success'));
            if ($formToSubmitOnSuccess.length) {
                $formToSubmitOnSuccess.trigger('submit');
            }
            else if (data.redirect) {
                if ($form.data('datalayer-on-success-url-load')) {
                    GaTracking.pushDataLayer($form.data('datalayer-on-success-url-load'), $form.data('is-multiple-events'));
                }
                window.location.assign(data.redirect);
            }
            else if (successTemplate) {
                const $newTemplateContainer = $($form.data('new-template-container'));
                if ($newTemplateContainer.length) {
                    $newTemplateContainer.html(data);
                    $($form.data('old-template-container')).addClass('d-none');
                    $newTemplateContainer.removeClass('d-none');
                }
                else {
                    $form.parent().html(data);
                }
                LazyImages.init();
                SwitchElements.init();
                CodeInput.init();
                Timer.stop();
                Timer.init();
                CheckboxGaEvents.init();
                new AjaxForm($('.js__ajax-form-in-ajax-form'), successUrl, this.successCallback);
                if ($form.data('datalayer-on-new-template')) {
                    GaTracking.pushDataLayer($form.data('datalayer-on-new-template'), $form.data('is-multiple-events'));
                }
            }
            else if (successUrl) {
                if ($form.data('datalayer-on-success-url-load')) {
                    GaTracking.pushDataLayer($form.data('datalayer-on-success-url-load'));
                }
                window.location.assign(successUrl);
            }
        })
            .fail(data => {
            $form[0].dispatchEvent(this.event);
            const dataLayers = $form.data('data-layers');
            const message = data.responseJSON ? data.responseJSON.message : '';
            const messageKey = data.responseJSON ? data.responseJSON.messageKey : '';
            const inputsToClear = $form.data('clear-inputs-on-error');
            const errorDatalayer = $form.data('datalayer-on-error');
            const $messageTooManyRequests = $form.find('.js__too-many-requests-error');
            if (data.status === 429) {
                $messageTooManyRequests.removeClass('d-none');
            }
            if (errorDatalayer && message) {
                GaTracking.pushDataLayer({
                    ...errorDatalayer,
                    eventLabel: `Error - ${getEnglishErrorMessageByMessageText(message)}`,
                });
            }
            if (messageKey && messageKey.includes('CSRF')) {
                window.location.reload();
            }
            else if (message) {
                this.addGeneralError($form, message);
            }
            else {
                const $contentBody = $(data.responseText);
                const $errors = $contentBody.find('.invalid-feedback');
                const $generalErrors = $contentBody.find($form.data('error-class-in-response-template'));
                if ($generalErrors.length) {
                    this.addGeneralError($form, $generalErrors.first().html());
                }
                $errors.each((index, el) => {
                    const $error = $(el);
                    const id = $error.siblings('.form-control').attr('id');
                    const $formGroup = $form.find(`#${id}`).addClass('is-invalid').parent();
                    const $currentError = $formGroup.find('.invalid-feedback');
                    if ($currentError.length) {
                        $currentError.replaceWith($error);
                    }
                    else {
                        $formGroup.append($error);
                    }
                });
                if (errorDatalayer && $errors.length) {
                    GaTracking.pushDataLayer({
                        ...errorDatalayer,
                        eventLabel: `Error - ${getEnglishErrorMessageByMessageText($errors.first().find('div').html())}`,
                    });
                }
            }
            if (inputsToClear) {
                $.each(inputsToClear.split(','), (index, item) => {
                    $(`input[name="${item}"]`).val('');
                });
            }
            if (dataLayers) {
                $.each(dataLayers, (index, item) => {
                    const eventData = item;
                    if (eventData.event === 'systemError' && data.responseJSON) {
                        eventData.errorMessage = data.responseJSON.message;
                    }
                    GaTracking.pushDataLayer(eventData);
                });
            }
        })
            .always(() => {
            if (disableFieldsOnLoad) {
                enableAllInputs($form);
            }
            toggleButtonLoader($element, false);
        });
        if (disableFieldsOnLoad) {
            disableAllInputs($form);
        }
        return ajaxRequest;
    }
    initValidation($form) {
        $form.validate({
            ...validationOptions,
            rules: $form.data('rules'),
            messages: $form.data('messages'),
            ignore: $form.data('ignore-validation') || '.js__ignore-validation',
        });
    }
    addGeneralError($form, message) {
        const $customGeneralError = $($form.data('general-error-container'));
        const $generalError = $customGeneralError.length ? $customGeneralError : $form.find('.js__form-general-error');
        $generalError.html(message);
        $generalError.removeClass('d-none');
    }
    hideGeneralError($form) {
        const $customGeneralError = $($form.data('general-error-container'));
        const $generalError = $customGeneralError.length ? $customGeneralError : $form.find('.js__form-general-error');
        $generalError.addClass('d-none').html('');
    }
}
